<template>
  <div class="home">
    <h3 class="common-title">Dashboard</h3>
    <div class="clearfix pb20">
      <div class="fl pb5 pr5 pt5">
        <el-date-picker
          v-model="calendarValue"
          type="daterange"
          unlink-panels
          range-separator="-"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :shortcuts="shortcuts"
          size="default"
          @change="calendarChange"
        />

        <el-button
          class="ml10"
          type="primary"
          @click="getMerchantDashboard">
          检索
        </el-button>
      </div>
    </div>
    <div class="home-left mt30">
      <Empty v-if="dashboard === null"></Empty>
      <el-row :gutter="16" v-else>
        <el-col :span="6">
          <div class="statistic-card">
            <el-statistic title="问题数" :value="dashboard.query_count"/>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="statistic-card">
            <el-statistic title="用户数" :value="dashboard.user_count"></el-statistic>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="statistic-card">
            <el-statistic title="用户平均问题数" :value="dashboard.per_user_query_count"/>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="statistic-card">
            <el-statistic title="满意问答数" :value="dashboard.like_count">
            </el-statistic>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { reactive, ref } from 'vue'
import { dayjs } from 'element-plus'
import { authApi } from '@/api'
import { computed, onMounted } from "@vue/runtime-core";
import Empty from "@/components/Empty.vue";
import store from "@/store";
// @ is an alias to /src

export default {
  name: 'Home',
  components: { Empty },
  setup() {

    // merchant
    const merchant = computed(() => {
      return store.getters.merchant
    })

    const dashboard = ref(null)
    const getMerchantDashboard = () => {
      console.log(merchant.value)
      authApi.getMerchantDashboard(merchant.value.id, dashboardFilter).then(res => {
        console.log(res)
        dashboard.value = res.data
      })
    }

    const calendarValue = ref(null)

    const dashboardFilterRaw = reactive({
      start_date: null,
      end_date: null,
    })
    const dashboardFilter = reactive(JSON.parse(JSON.stringify(dashboardFilterRaw)))

    const dateFormat = (date) => {
      return dayjs(date).format('YYYY-MM-DD')
    }

    const shortcuts = [
      {
        text: '最近一周',
        value: () => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
          return [start, end]
        },
      },
      {
        text: '最近一个月',
        value: () => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
          return [start, end]
        },
      },
      {
        text: '最近三个月',
        value: () => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
          return [start, end]
        },
      },
      {
        text: '最近半年',
        value: () => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 180)
          return [start, end]
        },
      }
    ]

    const calendarChange = (val) => {
      console.log(val)
      dashboardFilter.start_date = dateFormat(val[0])
      dashboardFilter.end_date = dateFormat(val[1])
    }

    onMounted(() => {
      const end = new Date()
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
      calendarValue.value = [start, end]
      dashboardFilter.start_date = dateFormat(start)
      dashboardFilter.end_date = dateFormat(end)
      getMerchantDashboard()
    })
    return {
      dashboard,
      merchant,
      dashboardFilter,
      shortcuts,
      calendarValue,
      calendarChange,
      dateFormat,
      getMerchantDashboard
    }
  }
}
</script>
<style scoped>
.el-col {
  text-align: center;
}

.statistic-card {
  height: 70%;
  padding: 20px;
  border-radius: 4px;
  background-color: rgb(240, 240, 240);
}
</style>
